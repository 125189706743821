<template>
  <Page color="info" :title="schoolClass.code">
    <template slot="extension">
      <v-tabs background-color="info darken-1" rounded align-with-title>
        <v-tab :to="{ name: 'StudentSchoolClassList' }"> Schüler:innen</v-tab>
        <v-tab :to="{ name: 'StudentSchoolClassRegister' }"> Klassenbuch</v-tab>
        <v-tab :to="{ name: 'StudentSchoolClassExams' }"> Probenplan</v-tab>
        <v-tab :to="{ name: 'StudentSchoolClassTeam' }">Klassenteam</v-tab>
      </v-tabs>
    </template>
    <router-view :schoolClass="schoolClass" @updated="fetchData"></router-view>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import { formatDatespan } from "common/utils/date.js";
import { sortPeople } from "common/utils/people.js";
export default defineComponent({
  name: "SchoolClass",
  data() {
    return {
      loading: true,
      search: "",
      schoolClass: {},
    };
  },
  methods: {
    formatDatespan,
    async fetchData() {
      if (!this.$_profilePerson.schoolClass) return;
      this.loading = true;
      this.schoolClass = await this.apiGet({
        resource: "schoolclass/schoolclass",
        id: this.$_profilePerson.schoolClass.id,
      });
      this.schoolClass.students = sortPeople(this.schoolClass.students);
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
